.paragraph {
    padding: 0.3rem;
    font-size: large;
    background-color: whitesmoke;
    opacity: 0.6;
    border-radius: 5px;
    box-shadow: gray 2px 2px 2px;
}
.profile-pic {
    margin-top: 1.5rem;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    border: 1px solid black;
}