.botonera {
    display: grid;
    grid-template-columns: minmax(50vw, 80vw);
    gap: 3rem;
    justify-content: center;
    justify-items: center;
    margin-top: 12%;
    /* background-image: url(../../assets/patin.jpg);
    background-repeat: no-repeat;
    background-size: contain; */
}

.btn-custom {
    color: white !important;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    width: 70vw;
    height: 3rem;
    box-shadow: whitesmoke 3px 2px 5px;
    /* opacity: 0.95; */
    font-size: large;
    cursor: pointer;
    text-decoration: none;
}
.btn-custom:active {
    transform: translateY(2px) translateX(1px);
    color: white !important;
}
.btn-custom:active .custom-p{
  font-size: 1.4rem;
}
.facebook {
    background-color: #548EC6;
}
.maps {
  /* background-color: #42C453; */
  background-color: #E73B17;
}
.whatsapp {
  background-color: #34A853;
}
.info-button {
  background-color: #90BEC7;
}
.web {
  background-color: #25c2e1;
}
.instagram-button {
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    border: none;
    color: white;
    transition: background 0.3s ease;
}
.instagram-button:hover {
  background: linear-gradient(45deg, #e1306c, #fd1d1d, #405de6, #5851db, #833ab4, #c13584);
}
.tiktok-button {
  background-color: #373837;
}
.icon-social {
  height: 4rem;
  width: 4rem;
}
.tiktok-img {
 filter: drop-shadow(0 0 0 5px  white);
 width: 3.6rem;
 height: 3.6rem;
 
}
.custom-p {
  margin: auto;
  font-size: 1.2rem;
}
.text-p {
  color: white;
}
@media (max-width: 800px) {
  .main-title {
    font-size: 2.6rem;
  }
}