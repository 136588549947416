.App {
  background-color: black;
}
.container {
  text-align: center;
  padding: 1.5rem;
  font-family: 'Baloo 2', cursive;
  filter: drop-shadow(2px 4px 6px #FA44D7);
}
.navbar {
  background: rgb(250,69,216);
  background: linear-gradient(225deg, rgba(250,69,216,1) 0%, rgba(126,6,201,1) 60%);

}
.navbar-brand {
  text-shadow: #FA44D7 3px 2px 4px;
  font-size: 2rem;
}
@media (max-width: 800px) {
  .navbar-brand {
    font-size: 1.6rem;
  }
  .roller-img {
    width: 40px;
    height: 40px;
  }
}